import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { graphql } from 'react-apollo';
import { List, Card, Icon, Dropdown, Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import _ from 'lodash';

import AccountEdit from './Edit';
import AccountDelete from './Delete';
import AccountActivate from './Activate';
import AccountWithdrawal from './Withdraw';

import banks from '../../lib/banks';
import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(
  gql`
    mutation updateOrganizationAccount(
      $updateOrganizationAccountMutation: UpdateOrganizationAccountInput!
    ) {
      updateOrganizationAccount(input: $updateOrganizationAccountMutation) {
        organizationAccount {
          dbId
        }
      }
    }
  `,
  {
    options: {
      refetchQueries: ['EntityBankAccountsQuery', 'AccountEditQuery'],
    },
  },
)
@observer
export default class AccountItem extends React.Component {
  static fragments = {
    account: gql`
      fragment AccountItem on OrganizationAccount {
        id: dbId
        organizationId
        balance
        bank
        legalName
        bankName
        taxId
        transferInterval
        transferDay
        absorbedFee
        default
        phone
        email
        holderName
        zipCode
        addressState
        city
        neighborhood
        addressStreet
        addressNumber
        addressComplement
        credentials
        status
      }
    `,
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  updateDefault = () => {
    const { store, mutate } = this.props;
    this.setState({ isLoading: true });

    return mutate({
      variables: {
        updateOrganizationAccountMutation: {
          id: this.props.account.id,
          default: true,
          entityId: store.currentEntity.id,
        },
      },
    })
      .then(() => {
        store.snackbar = {
          active: true,
          message: __('Default bank changed successfully'),
          success: true,
        };
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        store.snackbar = {
          active: true,
          message: utils.handleError(err.graphQLErrors[0]),
          success: false,
          dismissAfter: 6000,
        };
        this.setState({ isLoading: false });
      });
  };
  renderWithdrawalModal = (account, bankDescription) => {
    const { store } = this.props;
    const MINIMUM_AMOUNT = 1;
    if (account.balance.available >= MINIMUM_AMOUNT) {
      this.props.store.appends.push(
        <AccountWithdrawal
          account={account}
          bankDescription={bankDescription}
        />,
      );
    } else {
      store.snackbar = {
        active: true,
        message: utils.handleError(
          'The amount must be greater than or equal to 100',
        ),
        success: false,
        dismissAfter: 6000,
      };
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { account, store, allBanks } = this.props;
    const {
      legalName,
      id,
      default: primary,
      bank,
      currency,
      balance,
    } = account;

    const hasAdmin =
      store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization &&
        store.currentEntity.organization.permissions &&
        store.currentEntity.organization.permissions.hasAdmin);

    const hasCreatePermission =
      store.currentEntity.organization &&
      store.currentEntity.organization.permissions &&
      store.currentEntity.organization.permissions.entityScopes.includes(
        'CREATE_UPDATE_ACCOUNT',
      );
    const hasDeletePermission =
      store.currentEntity.organization &&
      store.currentEntity.organization.permissions &&
      store.currentEntity.organization.permissions.entityScopes.includes(
        'DELETE_ACCOUNT',
      );
    const isPrimary = primary;
    const isMaster = store && store.currentUser && store.currentUser.isMaster;
    const isActive = account.status === 'ACTIVE';
    const isNotCreated = account.status === 'NOT_CREATED';

    let items = [];

    if (isActive && !isPrimary && (hasCreatePermission || hasAdmin)) {
      items = items.concat([
        <Dropdown.Item
          onClick={() => this.updateDefault()}
          loading={this.state.isLoading}
        >
          <Icon name="star" />
          {__('Make it primary')}
        </Dropdown.Item>,
      ]);
    }

    if (isActive && (hasCreatePermission || hasAdmin)) {
      items = items.concat([
        <Dropdown.Item
          onClick={() =>
            window.open(
              `https://share.hsforms.com/1j_ecIkCVT9C1z-k7dZnoIQ3jwc6?subject=${store.currentOrganization.fullname}&id_classapp=${store.currentOrganization.id}`,
            )
          }
        >
          <Icon name="edit" style={{ marginLeft: '2px' }} />
          {__('Edit account')}
        </Dropdown.Item>,
      ]);
    }

    if (isNotCreated && isMaster) {
      items = items.concat([
        <Dropdown.Item
          onClick={() =>
            store.appends.push(
              <AccountActivate account={account} params={this.props.params} />,
            )
          }
        >
          <Icon name="check" style={{ marginLeft: '2px' }} />
          {__('Activate account')}
        </Dropdown.Item>,
      ]);
    }

    if (!isActive && isMaster) {
      items = items.concat([
        <Dropdown.Item
          onClick={() =>
            store.appends.push(
              <AccountDelete account={account} params={this.props.params} />,
            )
          }
        >
          <Icon name="remove" style={{ marginLeft: '2px' }} />
          {__('Remove account')}
        </Dropdown.Item>,
      ]);
    }

    const bankInfo = _.split(bank, ',');
    const bankDescription =
      allBanks &&
      allBanks.find(
        (item) =>
          item.label === bankInfo[0] || item.code === parseInt(bankInfo[0]),
      );

    const isDisabled = !isActive || !(hasAdmin || hasCreatePermission);
    const opacity = isActive ? 1 : 0.6;

    return account.organizationId === store.currentOrganization.id ? (
      <div className="translate-child">
        <List.Item
          className="accountItem"
          data-id={id}
          as={Card}
          style={{
            width: '287px',
            cursor: 'default',
            backgroundColor: '#fff',
            opacity,
          }}
        >
          <Card.Content>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: 'rgba(0,0,0,.87)',
              }}
            >
              {isPrimary && (
                <Icon
                  name="star"
                  style={{
                    position: 'absolute',
                    left: '8%',
                    color: 'rgba(0,0,0,.4)',
                  }}
                />
              )}
              <Card.Header
                aria-label={'account-card-' + id}
                title={'ID:' + id}
                style={{
                  fontSize: '16px',
                  maxWidth: 200,
                  textAlign: 'center',
                  color: 'rgba(0,0,0,.87)',
                  fontWeight: 'bold',
                }}
              >
                {legalName}
              </Card.Header>
              <div style={{ position: 'absolute', left: '77%' }}>
                {(items.length && isActive) || isMaster ? (
                  <Dropdown
                    icon={null}
                    className="icon"
                    trigger={
                      <Icon
                        data-cy="open-account-options"
                        name="ellipsis v"
                        style={{
                          width: '3.9px',
                          height: '13.6px',
                          marginLeft: '45px',
                          color: 'rgba(0,0,0,.4)',
                        }}
                      />
                    }
                  >
                    <Dropdown.Menu>{items}</Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </div>
            </div>
            <Card.Description style={{ color: '#212529', marginTop: 5 }}>
              <p>
                <span
                  style={{
                    display: 'block',
                    color: '#868e96',
                    fontSize: '12px',
                    textAlign: 'center',
                  }}
                >
                  {(bankDescription && bankDescription.value) || '-'}
                </span>
              </p>
              <div
                style={{
                  height: 'auto',
                  width: '239px',
                  borderRadius: '10px',
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <div style={{ color: '#6c6c6c', fontSize: '12px' }}>
                  {__('AVAILABLE BALANCE')}
                </div>
                <div
                  style={{
                    color: '#0BAE6D',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {balance.available.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: currency || 'BRL',
                  })}
                </div>
              </div>
              <div
                style={{
                  height: 'auto',
                  width: '239px',
                  borderRadius: '10px',
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <div style={{ color: '#6c6c6c', fontSize: '12px' }}>
                  {__('EXPECTED BALANCE')}
                </div>
                <div
                  style={{
                    color: '#0069ff',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {balance.waiting_funds.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: currency || 'BRL',
                  })}
                </div>
              </div>
              <div>
                <Button
                  key={id}
                  basic
                  circular
                  disabled={isDisabled}
                  color="white"
                  onClick={() =>
                    this.renderWithdrawalModal(account, bankDescription)
                  }
                  style={{
                    width: '239px',
                    height: '40px',
                    fontWeight: 'bold',
                    border: isDisabled && '1px solid #e9ecef',
                  }}
                >
                  {__('Withdraw')}
                </Button>

                <Button
                  key={id + 'statement'}
                  as={Link}
                  to={`/organizations/${store.currentOrganization.id}/accounts/${account.id}/statement`}
                  basic
                  circular
                  disabled={isDisabled}
                  color="white"
                  style={{
                    marginTop: 10,
                    width: '239px',
                    height: '40px',
                    fontWeight: 'bold',
                    border: isDisabled && '1px solid #e9ecef',
                  }}
                >
                  {__('Get statement')}
                </Button>
              </div>
            </Card.Description>
          </Card.Content>
        </List.Item>
      </div>
    ) : (
      <div />
    );
  }
}
